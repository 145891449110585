let code = '';
const baseUrl = "https://api.giztix.com";

if (baseUrl === 'https://api.giztix.com') {
    code = 'UA-68370537-2';
}

if (baseUrl === 'https://demoapi.giztix.com') {
    code = 'UA-124409220-1';
}

export default code;
