import React from 'react';
import {
	Collapse,
	Navbar,
	NavbarToggler,
	NavbarBrand,
	Nav,
	NavItem,
	NavLink,
  } from 'reactstrap';
// import Logo from "../../assets/img/logo-giztix.png";
import Logo from "../../assets/img/logov2.png";
// const navbarheader = {
//   position: 'relative',
//   width: '100%'
// }
class HeaderDesktop extends React.Component  {
	constructor(props) {
		super(props);
	
		this.toggle = this.toggle.bind(this);
		this.state = {
		  isOpen: false
		};
	  }
	  toggle() {
		this.setState({
		  isOpen: !this.state.isOpen
		});
	  }

  render() {
    return (
      
        <Navbar color="faded" light>
          
          <div className="container" >
          <NavbarBrand href="/" className="mr-auto"><img  width='100%' src={Logo} alt="background_image" /></NavbarBrand>
		  <NavbarToggler onClick={this.toggle} />
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="ml-auto" navbar>
                <NavItem>
                  <NavLink href="/tracking">ติดตามพัสดุ</NavLink>
                </NavItem>
                
              </Nav>
            </Collapse>
          </div>
        </Navbar>
    );
  }
}
export default  HeaderDesktop;