import React, { Component } from "react";
import Sidenavs from '../../components/Sidenavs/Sidenavs';
import { FormGroup,Label,Table, Button, Row, Col, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import axios from "axios";
import { red } from "ansi-colors";
import moment from "moment";
class Tracking extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            tracking: "",
            trackingnumber: "",
            objtracking: "",
            check: 0,
            classcheck: "hide",
        }
    }
    checkTracking = () =>{
        this.setState({ 
            classcheck : "show",
            check: 0,
        });
        var data = {tracking_number: this.state.tracking};
        const headers = {
            'Content-Type': 'application/json'
        };
        axios.post('https://api.giztix.com/v1/giztixpaceltracking',data,{headers: headers})
        .then(response => {
            this.setState({ 
                objtracking : response.data,
                check: 1,
            });
            this.state.trackingnumber = this.state.tracking;
        })
        .catch(error => {
            console.log(error);
        });
    }
    render() {
    const square = {
        position: 'absolute',
        height: '10px',
        width: '10px',
        backgroundColor: '#D90101',
    };
    const btncenter = {
        verticalAlign: 'inherit',
        width: '100%'
        
    };
    if (this.state.tracking === "") {
        this.state.trackingnumber = "";
        this.state.check = "";
        this.state.classcheck = "hide";
    }
    console.log(this.state.objtracking);
    return (
        <div className="page-tracking">
                    <Sidenavs />
            <div className="container ">
                <Row>
                    <Col md={12}>
                        <h4 style={{fontWeight: 'bold',marginTop: '40px'}}>ติดตามพัสดุ <span style={square}></span></h4>
                    </Col>
                </Row>
                <div className="content-step" style={{paddingTop: '65px'}}>
                    <div className="content" style={{paddingBottom: '120px'}}>
                        <Row>
                            <Col sm="12" md={{ size: 6, offset: 3 }}>
                                <FormGroup>
                                    <Label style={{width: '100%',textAlign: 'center'}}><h3 style={{fontWeight: 'bold', overflowY: 'hidden'}}>ติดตามสถานะงานขนส่ง</h3></Label>
                                    <InputGroup>
                                        <Input type="search" name="numtracking" placeholder="กรอกรหัสติดตามพัสดุ" value={this.state.tracking} onChange={(event) => this.setState({ tracking: event.target.value,})} />
                                        <InputGroupAddon addonType="append">
                                        <Button style={btncenter}  color="danger" onClick={this.checkTracking}>ติดตาม</Button>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                        </Row>
                        <div className={this.state.classcheck}>
                            {this.state.check === 1 ? (
                            <Row>
                                {this.state.objtracking.error === null ? (
                                        <Col sm="12" md={{ size: 10, offset: 1}}>
                                            <Table className="tracking_status">
                                                <thead>
                                                <tr>
                                                    <th style={{borderTopLeftRadius: "6px"}}>เวลาท้องถิ่น</th>
                                                    <th>สถานที่</th>
                                                    <th style={{borderTopRightRadius: "6px"}}>การดำเนินการ </th>
                                                </tr>
                                                </thead>
                                                <tbody>

                                                {this.state.objtracking.tracking_status.map((obj,index) => {         
                                                return (
                                                    <tr  key={index}>
                                                        <td>{moment(obj.date).format("DD/MM/YYYY HH:mm")}</td>
                                                        <td>{obj.location}</td>
                                                        <td>{obj.status_description}</td>
                                                    </tr>
                                                ) 
                                                })}
                                                </tbody>
                                            </Table>
                                        </Col>
                                    ):(
                                        <Col sm="12" md={{ size: 10, offset: 1}}>
                                            <h3 style={{color: "red", marginTop: "50px", textAlign: "center",fontWeight: "bold"}}>{this.state.objtracking.error}</h3>
                                        </Col>
                                    )
                                }  
                            </Row>
                            ):(
                                <div style={{ marginTop: "50px", textAlign: "center",fontWeight: "bold"}}>Loading...</div>
                            )
                            }
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        
    );
    }
  }
  
  export default Tracking;