import React from 'react';
import HeaderDesktop from './../HeaderDesktop';
import HeaderMobile from './../HeaderMobile';


class Header extends React.Component {
	constructor(props) {
		super(props);
		this.state = {width: props.width};
	}
	componentWillMount() {
		this.setState({width: window.innerWidth});
	}

	render(props) {
		return (<HeaderDesktop  {...this.props} />);
	}
}

export default Header;
