import React from 'react';
import PropTypes from 'prop-types';
import { Row , Col } from 'reactstrap';
import styled from 'styled-components';
import appIos from '../../assets/images/app-store.svg';
import playStore from '../../assets/images/play-store.svg';
import facebookfooter from "../../assets/img/facebookfooter.png";
import linefooter from "../../assets/img/linefooter.png";
import instagram from "../../assets/img/instagram.png";
import youtube from "../../assets/img/youtube.png";
const FooterContainer = styled.div`
	background-color: #000;
	color: rgba(255,255,255,.6);
	padding: 80px 0 60px;

	& p {
		line-height: 1;
	}
`;
const MenuContainer = styled(Row)`
	padding-bottom: 40px;
`;
const DownloadContainer = styled(Row)`
	padding-top: 40px;
	padding-bottom: 40px;
	border-top: 1px solid rgba(255,255,255,0.2);
`;
const LoadAppContainer = styled.div`
	
`;

const CopyRightContainer = styled(Row)`
	padding-top: 40px;
	border-top: 1px solid rgba(255,255,255,0.2);
`;
const Title = styled.div`
	color: rgba(255,255,255,1);
	margin-bottom:10px;
`;
const LinkImg = styled.a`
	margin-right:10px;
`;
const Img = styled.img`
	height: 40px;
    border-radius: 4px;
    border: none;
`;

const Link = styled.a`
	color: rgba(255,255,255,.6);
	margin-bottom: 5px;
	width: 100%;
	float: left;
`;
const SocailLinkContainer = styled.div`
	width: 100%;
	float: left;
`;
const SocailLink = styled.a`
	display: inline-block;
	margin-right: 20px;
	font-size: 27px;
	line-height: 30px;
	color: rgba(255,255,255,.6);
`;


class Footer extends React.Component {
	state = {
		openMenu: false,
	};
	
	componentDidMount () {
		const script = document.createElement("script");

		script.src = "https://www.trustmarkthai.com/callbackData/initialize.js?t=ac54f3-22-5-c2eebed8b4423b9f63231bdf5fe34ef38a451cc";
		script.id = 'dbd-init';
		script.async = true;
		document.body.appendChild(script);
}

	render() {
		return (
			<FooterContainer>
				<div className="container">
					<MenuContainer>
						<Col md={6} sm={12}>
							<Title>ติดต่อฝ่ายลูกค้าสัมพันธ์</Title>
							<p>
								บริษัท จิซทิกซ์ จำกัด<br/>
								เลขที่ 101 , ตึก ทรู ดิจิทัล พาร์ค ชั้น 15 ห้องเลขที่ 1507-1509<br/>
								ถนน สุขุมวิท แขวงบางจาก เขตพระโขนง กรุงเทพ 10260
							</p>
							<p>
								อีเมล : <a href="mailto:service@giztix.com" style={{color: "#ccc"}}>service@giztix.com</a><br/>
								เบอร์โทรศัพท์ : 02-059-7149<br/>
								จันทร์ - ศุกร์ เวลา 09.00 - 18.00 น.
							</p>
						</Col>
						<Col md={2} sm={4}>
							<Title>เมนูหลัก</Title>
							<Link href="http://parcel.giztix.com/tracking">ติดตามสถานะงานขนส่ง</Link>
							<Link href="https://www.giztix.com/pricing">อัตราค่าบริการขนส่ง</Link>
							<Link href="https://blog.giztix.com/">ข่าวสารและโปรโมชั่น</Link>
							<Link href="https://www.giztix.com/helpcenter">ศูนย์ความช่วยเหลือ</Link>
						</Col>
						<Col md={2} sm={4}>
							<Title>เกี่ยวกับ</Title>
							<Link href="https://www.giztix.com/aboutus">ทำไมต้องจิซทิกซ์?</Link>
							<Link href="https://www.giztix.com/contactus">ติดต่อจิซทิกซ์</Link>
							<Link href="https://www.giztix.com/complain">การแก้ปัญหาข้อร้องเรียน</Link>
						</Col>
						<Col md={2} sm={4}>
							<Title>บริการอื่น ๆ</Title>
							<Link href="http://thumbweb.maninnovation.com/">ThumbWeb</Link>
							<Link href="http://parcel.giztix.com/">บริการขนส่งพัสดุ (Beta)</Link>
						</Col>
					</MenuContainer>
					<DownloadContainer>
						<Col md={6} sm={6}>
							<Title>ดาวน์โหลดแอปพลิเคชันได้ที่</Title>
							<LoadAppContainer>
								<LinkImg className="img" href="https://itunes.apple.com/app/id1439755826" target="_blank"><Img src={appIos} className="logo" alt="logo" /></LinkImg>
            					<LinkImg className="img" href="https://play.google.com/store/apps/details?id=com.giztix.app" target="_blank"><Img src={playStore} className="logo" alt="logo" /></LinkImg>
							</LoadAppContainer>
						</Col>
						<Col md={4} sm={6}>
							<Title>ติดตามข่าวสารและความเคลื่อนไหวได้ที่</Title>
							<SocailLinkContainer>
								<SocailLink href="https://www.facebook.com/giztix/" target="_blank" title="Facebook" aria-label="Facebook"><img width='28px' height='auto' src={facebookfooter} alt="line_icon" /></SocailLink>
								<SocailLink href="https://line.me/R/ti/p/@giztix" target="_blank" title="Line" aria-label="Line"><img width='29px' height='auto' style={{backgroundColor: '#868686',borderRadius: '4px'}} src={linefooter} alt="line_icon" /></SocailLink>
								<SocailLink href="https://www.instagram.com/giztix_official/" target="_blank" title="Instagram" aria-label="Instagram"><img width='30px' height='auto' src={instagram} alt="line_icon" /></SocailLink>
								<SocailLink href="https://www.youtube.com/channel/UCDoz39PN2yzoQB5d0-xcgJg" target="_blank" title="Youtube" aria-label="Youtube"><img width='35px' height='auto' src={youtube} alt="line_icon" /></SocailLink>
							</SocailLinkContainer>
						</Col>
						<Col md={2} sm={12} style={{textAlign:"right"}}><div id="Certificate-banners"></div></Col>
					</DownloadContainer>
					<CopyRightContainer>
						<Col md={6} sm={12}>
							<span>© 2019 GIZTIX - สงวนลิขสิทธิ์ (เวอร์ชั่น 1)</span>
						</Col>
						<Col md={2} sm={4}>
							<Link href="https://www.giztix.com/termscondition">เงื่อนไขการให้บริการ</Link>
						</Col>
						<Col md={2} sm={4}>
							<Link href="https://www.giztix.com/policy">นโยบายการคุ้มครอง</Link>
						</Col>
						<Col md={2} sm={4}>
							<Link href="https://www.giztix.com/standard">มาตรฐานการให้บริการ</Link>
						</Col>
					</CopyRightContainer>
				</div>
			</FooterContainer>	
		);
	}
}

Footer.propTypes = {
	active: PropTypes.number,
};

Footer.defaultProps = {
	active: null,
};

export default Footer;
