import React from 'react';
import { Navbar, NavbarBrand } from 'reactstrap';
import Logo from "../../assets/img/logo_white-01.svg";
class HeaderDesktop extends React.Component  {
  constructor(props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render() {
    return (
      
        <Navbar color="faded" light>
          <div className="container" >
          <NavbarBrand href="https://www.giztix.com" className="mr-auto"><img  width='170px' src={Logo} alt="background_image" /></NavbarBrand>
          <a className="btn-tracking text-white" href="/tracking">ติดตามพัสดุ</a> 
          {/* | <a className="btn-payment" href="/payment">แจ้งชำระเงิน</a> */}
          </div>
        </Navbar>
    );
  }
}
export default  HeaderDesktop;