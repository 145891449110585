import React, { Component } from "react";
import './App.css';
import Footer from './components/Footer';
import Getprice from './views/Getprice/Getprice';
import { Route,Switch } from 'react-router-dom';
import Booking from './views/Booking/Booking';
import Tracking from './views/Tracking/Tracking';


import ReactGA from 'react-ga';
import gaCode from './config/gaCode';
ReactGA.initialize(gaCode);
ReactGA.pageview(window.location.pathname);

class App extends Component {
  render() {
    return (
      <div>
        {/* <Getprice /> */}
        {/* <Tracking /> */}
          <Route exact path="/" component={Getprice} />
          <Route path="/tracking" component={Tracking} />
          <Route path="/booking" component={Booking} />
          
        <Footer />
      </div>
    );
  }
}

export default App;
    