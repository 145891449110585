import React from 'react';
import { Navbar, NavbarBrand } from 'reactstrap';
// import Logo from "../../assets/img/logo-giztix.png";
import Logo from "../../assets/img/logo_black-01.svg";
// const navbarheader = {
//   position: 'relative',
//   width: '100%'
// }
export default class Sidenavs extends React.Component {
  constructor(props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render() {
    return (
      
        <Navbar color="faded" light>
          
          <div className="container" >
          <NavbarBrand href="https://www.giztix.com" className="mr-auto"><img  width='170px' src={Logo} alt="background_image" /></NavbarBrand>
          <a className="btn-tracking" href="/">เช็คราคาบริการขนส่งพัสดุ</a> 
          {/* | <a className="btn-payment" href="/payment">แจ้งชำระเงิน</a> */}
          </div>
        </Navbar>
    );
  }
}