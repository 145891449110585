import React, { Component } from "react";
import Sidenavs from '../../components/Sidenavs/Sidenavs';
import { Col, Row} from 'reactstrap';
class Booking extends Component {
    render() {
        const square = {
            position: 'absolute',
            height: '10px',
            width: '10px',
            backgroundColor: '#D90101',
        };
        
    return (
        <div>
            <div className="container" style={{borderBottom: '1px solid #E3E3E3'}}>
                <Row>
                    <Col md={12}>
                    <Sidenavs />
                    </Col>
                </Row>
            </div>
            <div className="container">
                <Row>
                    <Col md={12}>
                        <h4 style={{fontWeight: 'bold',marginTop: '40px'}}>สร้างรายการขนส่ง<span style={square}></span></h4>
                    </Col>
                </Row>
                <div className="content-step">
                    <Row style={{flexWrap: 'unset'}}>
                        <Col md={4}>
                           <div className="step_1" style={{textAlign: 'left'}}>    
                                <div>กรอกรายละเอียด</div>
                                <div></div>
                            </div>
                        </Col>
                        <Col md={4}>
                           <div className="step_2" style={{textAlign: 'center'}}>    
                                <div>ยืนยันการจอง</div>
                                <div></div>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="step_3" style={{textAlign: 'right'}}>    
                                <div>แจ้งชำระเงิน</div>
                                <div></div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
        
    );
    }
  }
  
  export default Booking;