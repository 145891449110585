import React from 'react';
import { FormGroup, Table, Label, Button, Row, Col, Input, InputGroup, InputGroupText, InputGroupAddon } from 'reactstrap';
import province from '../../config/province/province.json';
import Lineicon from "../../assets/img/LINE_icon.png";
import Telicon from "../../assets/img/telicon.png";
import Email from "../../assets/img/gmail_icon.png";
import Header from '../../components/Header';
import MediaQuery from 'react-responsive';
const btncenter = {
    verticalAlign: 'inherit',
    width: '100%'
    
};
export default class Getprice extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
        weight: "",
        showResults: "hide",
        rangeweight: "",
        postcodeform: "",
        changwatsnameform: "",
        amphoesnameform: "",
        postcodeto: "",
        changwatsnameto: "",
        amphoesnameto: "",
        price: "",
        addressform: "",
        addressto: "",
    }
}
checkDisabled(){
    if(this.state.Addressform === "" || this.state.Addressto === "" || this.state.weight === ""){
        return true;
    }
    return false;
}
checkGiztix(){
    window.open("https://giztix.page.link/GTXTMY");
}
onClickget  = () =>  {
    let weight = this.state.weight;
    let price = 0;
    let zoneto = 0;
    let zoneform = 0;
    let amphoesform = "";
    let amphoesto = "";
    let changwatsform = 0;
    let changwatsto = 0;
    let rangeweight = "";
    let postcodeform = 0;
    let postcodeto = 0;
    let ooaform = 0;
    let ooato = 0;

    const listform = province.filter((list) => {
        return list.postcode == this.state.Addressform;
    })
    const listto = province.filter((list) => {
        return list.postcode == this.state.Addressto;
    })
    if(listform.length > 0){
        listform.forEach((list) => {
            zoneform = list.zone;
            amphoesform = list.amphoe_th;
            changwatsform = list.changwat_th;
            postcodeform = list.postcode;
        });
    }
    if(listto.length > 0){
        listto.forEach((list) => {
            zoneto = list.zone;
            amphoesto = list.amphoe_th;
            changwatsto = list.changwat_th;
            postcodeto = list.postcode;
            ooato = list.ooa;
        });
    }
    
    if(this.state.weight >= 0 && this.state.weight <= 5){
        rangeweight = "0-5";
        if (zoneform === "A" && zoneto === "A") {
            price = 84 * 1.3;
        }else if (zoneform === "A" && zoneto === "B") {
            price = 109 * 1.3;
        }else if (zoneform === "A" && zoneto === "C") {
            price = 126 * 1.3;
        }else if (zoneform !== "A" && zoneto === "A") {
            price = 119 * 1.3;
        }else if (zoneform !== "A" && zoneto !== "A") {
            price = 192 * 1.3;
        }
    }else if(this.state.weight > 5 && this.state.weight <= 10){
        rangeweight = "5-10";
        if (zoneform === "A" && zoneto === "A") {
            price = 105 * 1.3;
        }else if (zoneform === "A" && zoneto === "B") {
            price = 142 * 1.3;
        }else if (zoneform === "A" && zoneto === "C") {
            price = 147 * 1.3;
        }else if (zoneform !== "A" && zoneto === "A") {
            price = 151 * 1.3;
        }else if (zoneform !== "A" && zoneto !== "A") {
            price = 232 * 1.3;
        }
    }else if(this.state.weight > 10  && this.state.weight <= 20){
        rangeweight = "10-20";
        if (zoneform === "A" && zoneto === "A") {
            price = 135 * 1.3;
        }else if (zoneform === "A" && zoneto === "B") {
            price = 172 * 1.3;
        }else if (zoneform === "A" && zoneto === "C") {
            price = 202 * 1.3;
        }else if (zoneform !== "A" && zoneto === "A") {
            price = 174 * 1.3;
        }else if (zoneform !== "A" && zoneto !== "A") {
            price = 302 * 1.3;
        }
    }else if(this.state.weight > 20  && this.state.weight < 30){
        rangeweight = "21-29";
        let sumweight = parseInt(weight) - 20; 
        if (zoneform === "A" && zoneto === "A") {
            let sumper = sumweight * (12 * 1.3);
            price = 135 * 1.3 + sumper;
        }else if (zoneform === "A" && zoneto === "B") {
            let sumper =  sumweight * (14 * 1.3);
            price = 172 * 1.3 + sumper;
        }else if (zoneform === "A" && zoneto === "C") {
            let sumper =  sumweight * (18 * 1.3);
            price = 202 * 1.3 + sumper;
        }else if (zoneform !== "A" && zoneto === "A") {
            let sumper =  sumweight * (12 * 1.3);
            price = 174 * 1.3 + sumper;
        }else if (zoneform !== "A" && zoneto !== "A") {
            let sumper =  sumweight * (17 * 1.3);
            price = 302 * 1.3 + sumper;
        }
    }else if(this.state.weight == 30){
        rangeweight = "30";
        if (zoneform === "A" && zoneto === "A") {
            price = 255 * 1.3;
        }else if (zoneform === "A" && zoneto === "B") {
            price = 312 * 1.3;
        }else if (zoneform === "A" && zoneto === "C") {
            price = 382 * 1.3;
        }else if (zoneform !== "A" && zoneto === "A") {
            price = 294 * 1.3;
        }else if (zoneform !== "A" && zoneto !== "A") {
            price = 472 * 1.3;
        }
    }else if(this.state.weight > 30  && this.state.weight < 70){
        rangeweight = "31-69";
        let sumweight = parseInt(weight) - 30; 
        if (zoneform === "A" && zoneto === "A") {
            let sumper =  sumweight * (12 * 1.3);
            price = 255 * 1.3 + sumper;
        }else if (zoneform === "A" && zoneto === "B") {
            let sumper =  sumweight * (14 * 1.3);
            price = 312 * 1.3 + sumper;
        }else if (zoneform === "A" && zoneto === "C") {
            let sumper =  sumweight * (18 * 1.3);
            price = 382 * 1.3 + sumper;
        }else if (zoneform !== "A" && zoneto === "A") {
            let sumper =  sumweight * (12 * 1.3);
            price = 294 * 1.3 + sumper;
        }else if (zoneform !== "A" && zoneto !== "A") {
            let sumper =  sumweight * (17 * 1.3);
            price = 472 * 1.3 + sumper;
        }
    }else if(this.state.weight == 70){
        rangeweight = "70";
        if (zoneform === "A" && zoneto === "A") {
            price = 735 * 1.3;
        }else if (zoneform === "A" && zoneto === "B") {
            price = 872 * 1.3;
        }else if (zoneform === "A" && zoneto === "C") {
            price = 1102 * 1.3;
        }else if (zoneform !== "A" && zoneto === "A") {
            price = 774 * 1.3;
        }else if (zoneform !== "A" && zoneto !== "A") {
            price = 1152 * 1.3;
        }
    }else if(this.state.weight > 70  && this.state.weight < 100){
        rangeweight = "71-99";
        let sumweight = parseInt(weight) - 70;
        if (zoneform === "A" && zoneto === "A") {
            let sumper =  sumweight * (12 * 1.3);
            price = 735 * 1.3 + sumper;
        }else if (zoneform === "A" && zoneto === "B") {
            let sumper =  sumweight * (14 * 1.3);
            price = 872 * 1.3 + sumper;
        }else if (zoneform === "A" && zoneto === "C") {
            let sumper =  sumweight * (18 * 1.3);
            price = 1102 * 1.3 + sumper;
        }else if (zoneform !== "A" && zoneto === "A") {
            let sumper =  sumweight * (12 * 1.3);
            price = 774 * 1.3 + sumper;
        }else if (zoneform !== "A" && zoneto !== "A") {
            let sumper =  sumweight * (17 * 1.3);
            price = 1152 * 1.3 + sumper;
        }
    }else if(this.state.weight == 100){
        rangeweight = "100";
        if (zoneform === "A" && zoneto === "A") {
            price = 1095 * 1.3;
        }else if (zoneform === "A" && zoneto === "B") {
            price = 1292 * 1.3;
        }else if (zoneform === "A" && zoneto === "C") {
            price = 1642 * 1.3;
        }else if (zoneform !== "A" && zoneto === "A") {
            price = 1134 * 1.3;
        }else if (zoneform !== "A" && zoneto !== "A") {
            price = 1662 * 1.3;
        }
    }else if(this.state.weight > 100){
        rangeweight = "100 ขึ้นไป";
        let sumweight = parseInt(weight) - 70;
        if (zoneform === "A" && zoneto === "A") {
            let sumper =  sumweight * (12 * 1.3);
            price = 1095 * 1.3 + sumper;
        }else if (zoneform === "A" && zoneto === "B") {
            let sumper =  sumweight * (14 * 1.3);
            price = 1292 * 1.3 + sumper;
        }else if (zoneform === "A" && zoneto === "C") {
            let sumper =  sumweight * (18 * 1.3);
            price = 1642 * 1.3 + sumper;
        }else if (zoneform !== "A" && zoneto === "A") {
            let sumper =  sumweight * (12 * 1.3);
            price = 1134 * 1.3 + sumper;
        }else if (zoneform !== "A" && zoneto !== "A") {
            let sumper =  sumweight * (17 * 1.3);
            price = 1662 * 1.3 + sumper;
        }
    }
    this.setState({ 
        rangeweight: rangeweight,
        listall : listform,
    });
    this.state.listall = listform;
    this.state.showResults = "show";
    this.state.rangeweight = rangeweight;

    this.state.postcodeform = postcodeform;
    this.state.changwatsform = changwatsform;
    this.state.amphoesform = amphoesform;
    this.state.postcodeto = postcodeto;
    this.state.changwatsto = changwatsto;
    this.state.amphoesto = amphoesto;
    this.state.ooato = ooato;

    if (this.state.postcodeform) {
        this.state.addressform = this.state.changwatsform+","+this.state.postcodeform;
    }
    if (this.state.postcodeto) {
        this.state.addressto = this.state.changwatsto+","+this.state.postcodeto;
    }
    
    if (zoneform == 0) {
        this.state.addressform = "รหัสไปรษณีย์ไม่ถูกต้อง";
    }
    if (zoneto == 0) {
        this.state.addressto = "รหัสไปรษณีย์ไม่ถูกต้อง";
        
    }
    if(zoneform == 0 || zoneto == 0) {
        this.state.price = 0;
    }else{
        this.state.price = Math.ceil(price + ooato);
    }
  }
  handleKeyUp(event, input){
    const validatePhoneNumber = /^[0-9]*$/; 
    console.log(validatePhoneNumber);       
    if(validatePhoneNumber.test(event.target.value)){
        if(input == "Addressform"){
            this.setState({ Addressform: event.target.value,showResults:"hide"});
        }
        else if( input == "Addressto"){
            this.setState({ Addressto: event.target.value,showResults:"hide"});
        }
      console.log(this.state.Addressform);
    }
  }
  Bookservice(){
    window.location = "/booking";
  }
  render() {
    if (this.state.weight < 0) {
        this.state.weight = 0;
    }
    return (
        <div className="page-content">
            {/* <img style={imgStyle} width='100%' height='auto' src={imagine} alt="background_image" /> */}
            <Header />
            <div className="container" >
                <div className="content">
                    <Row>
                        <Col sm="12" md={{ size: 8, offset: 4 }}>
                            <h1 className="text-white">บริการขนส่งพัสดุทั่วไทย</h1>
                            <p className="text-white">บริการระดับพรีเมียม รับ-ส่งสินค้าถึงหน้าบ้าน</p>
                            <Row>
                                <Col sm="8">
                                    <Label className="text-white">รหัสไปรษณีย์</Label>
                                    <Row>
                                        <Col sm="6">
                                            <FormGroup>
                                                <Input type="tel" name="Addressform"  maxLength="5" value={this.state.Addressform} placeholder="ต้นทาง" onChange={(event) => this.handleKeyUp(event,"Addressform")} />
                                            </FormGroup>
                                        </Col>
                                        <Col sm="6">
                                            <FormGroup>
                                                <Input type="tel" name="Addressto" maxLength="5" value={this.state.Addressto} placeholder="ปลายทาง" onChange={(event) => this.handleKeyUp(event,"Addressto")}/>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col sm="4">
                                    <FormGroup>
                                    <Label className="text-white">น้ำหนัก </Label>
                                        <InputGroup>
                                            <Input type="number" name="weight" min={0} max={500} placeholder="กรอกน้ำหนัก" value={this.state.weight} onChange={(event) => this.setState({ weight: event.target.value ,showResults:"hide"})} />
                                            <InputGroupAddon addonType="append">
                                            <InputGroupText>kg.</InputGroupText>
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col sm="12" md={{ size: 6, offset: 6 }}>
                                    <Button className="btn-getprice" style={btncenter} disabled={this.checkDisabled()}  color="danger" size="lg" onClick={this.onClickget}>เช็คราคาฟรี</Button>
                                </Col>
                            </Row>
                        </Col>    
                    </Row>
                    <Row>
                        <Col sm="12">
                            <MediaQuery minWidth={992}>
                                <Table className={this.state.showResults}>
                                    <thead>
                                    <tr>
                                        <th style={{paddingLeft: '35px',textAlign: 'center'}}>น้ำหนัก(kg)</th>
                                        <th>ที่อยู่ต้นทาง</th>
                                        <th>ที่อยู่ปลายทาง </th>
                                        <th style={{textAlign: 'center'}}>ค่าบริการ</th>
                                    </tr>
                                    </thead>
                                        {this.state.weight > 500 ?
                                            (
                                            <tbody>
                                                <tr>
                                                    <td colSpan="4" style={{textAlign: 'center'}}>
                                                        <div>ไม่สามารถจัดส่งได้เนื่องจากน้ำหนักเกิน 500 กิโลกรัม</div>
                                                        <div>คุณสามารถจองรถเต็มคันได้ที่ <a href="javascript:;" onClick={this.checkGiztix}>GIZTIX</a></div>
                                                    </td> 
                                                </tr>
                                            </tbody>
                                            ) : 
                                            (
                                            <tbody>
                                                <tr>
                                                    <td style={{paddingLeft: '35px',textAlign: 'center'}}>{this.state.rangeweight}</td>
                                                    <td>{this.state.addressform}</td>
                                                    <td>{this.state.addressto}</td>
                                                    <td style={{color:"#D90101",textAlign: 'center'}}><span className="price">{this.state.price}</span> THB</td>
                                                    <td style={{textAlign: 'center'}}><Button outline color="danger" onClick={this.Bookservice}>จองบริการ</Button></td>
                                                </tr>
                                            </tbody>
                                            
                                            )   
                                        }
                                        
                                </Table>
                                <Row>
                                    <Col sm="12" md={{ size: 8, offset: 2 }}>
                                        <Table className={this.state.showResults}>
                                            <thead>
                                                <tr>
                                                    <th colSpan="3" style={{textAlign: 'center'}}>ช่องทางการจอง</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{textAlign: 'center', width:'35%'}}>
                                                        <a href="tel:02-059-7149"><img width='34px' height='auto' src={Telicon} alt="tel_icon" /> 02-059-7149</a>
                                                    </td>
                                                    <td style={{textAlign: 'center', width:'30%'}}>
                                                        <a href="http://line.me/ti/p/~@giztix"><img width='44px' height='auto' src={Lineicon} alt="line_icon" /> @giztix</a>
                                                    </td>
                                                    <td style={{textAlign: 'center', width:'35%'}}> 
                                                        <a href="mailto:service@giztix.com"><img width='38px' height='auto' src={Email} alt="email_icon" /> service@giztix.com</a>
                                                    </td>
                                                </tr>
                                                
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            </MediaQuery>
                            <MediaQuery maxWidth={991}>
                                <Table className={this.state.showResults}>
                                {this.state.weight > 500 ?
                                        (
                                        <tbody>
                                            <tr>
                                                <td style={{borderTop: 'none'}}>
                                                    <div>ไม่สามารถจัดส่งได้เนื่องจากน้ำหนักเกิน 500 กิโลกรัม</div>
                                                    <div>คุณสามารถจองรถเต็มคันได้ที่ <a href="javascript:;" onClick={this.checkGiztix}>GIZTIX</a></div>
                                                </td> 
                                            </tr>
                                        </tbody>
                                        ) : 
                                        (
                                        <tbody>
                                            <tr>
                                                <td style={{paddingLeft: '35px',borderTop: 'none'}}>น้ำหนัก(kg)</td>
                                                <td style={{borderTop: 'none'}}>{this.state.rangeweight}</td>
                                            </tr>
                                            <tr>
                                                <td style={{paddingLeft: '35px'}}>ที่อยู่ต้นทาง</td>
                                                <td>{this.state.addressform}</td>
                                            </tr>
                                            <tr>
                                                <td style={{paddingLeft: '35px'}}>ที่อยู่ปลายทาง</td>
                                                <td>{this.state.addressto}</td>
                                            </tr>
                                            <tr>
                                                <td style={{paddingLeft: '35px'}}>ค่าบริการ</td>
                                                <td style={{color:"#D90101"}}><span className="price">{this.state.price}</span> THB</td>
                                            </tr>
                                        </tbody>
                                        
                                        )   
                                    }
                                   
                                </Table>
                                <Row>
                                    <Col sm="12" md={{ size: 8, offset: 2 }}>
                                        <Table className={this.state.showResults}>
                                            <thead>
                                                <tr>
                                                    <th colSpan="3" style={{textAlign: 'center'}}>ช่องทางการจอง</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{textAlign: 'center', width:'33.33%'}}>
                                                        <a href="tel:02-059-7149"><img width='34px' height='auto' src={Telicon} alt="tel_icon" /></a>
                                                    </td>
                                                    <td style={{textAlign: 'center', width:'33.33%'}}>
                                                        <a href="http://line.me/ti/p/~@giztix"><img width='44px' height='auto' src={Lineicon} alt="line_icon" /></a>
                                                    </td>
                                                    <td style={{textAlign: 'center', width:'33.33%'}}> 
                                                        <a href="mailto:service@giztix.com"><img width='38px' height='auto' src={Email} alt="email_icon" /></a>
                                                    </td>
                                                </tr>
                                                
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            </MediaQuery>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
  }
}
