import React from 'react';
import { Row , Col } from 'reactstrap';
// import { Query } from 'react-apollo';
import styled from 'styled-components';
// import logo from './../../assets/images/logo_giztix.png';
// import appIos from './../../assets/images/app-store.svg';
// import playStore from './../../assets/images/play-store.svg';

// import version from './../../config/version';
import MediaQuery from 'react-responsive';
import facebookfooter from "../../assets/img/facebookfooter.png";
import linefooter from "../../assets/img/linefooter.png";
import instagram from "../../assets/img/instagram.png";
import youtube from "../../assets/img/youtube.png";

const FooterContainer = styled.div`
	background-color: #000;
	color: rgba(255,255,255,.6);
	padding: 30px 0 20px;
	text-align: center;
`;

const MenuContainer = styled(Row)`
	padding-bottom: 10px;
`;

const DownloadContainer = styled(Row)`
	padding-top: 10px;
	padding-bottom: 30px;
`;

const DBDContainer = styled(Row)`
	padding-bottom: 30px;
`;

const CopyRightContainer = styled(Row)`
	padding-top: 20px;
	border-top: 1px solid rgba(255,255,255,0.2);
`;

const Title = styled.div`
	color: rgba(255,255,255,1);
	margin-bottom:10px;
`;

const SocailLinkContainer = styled.div`
	width: 100%;
	float: left;
`;

const SocailLink = styled.a`
	display: inline-block;
	margin-right: 20px;
	font-size: 27px;
	line-height: 30px;
	color: rgba(255,255,255,.6);
`;

const TopContainer = styled.div`
	font-size: 18px;
	color: #000000;
	background-color: #F9F9F9;
	padding: 15px 0 30px;
	line-height: 1;
	text-align: center;
	cursor: pointer;
	width: 100%;
	display: block;

  & span {
	width: 100%;
	float: left;
  }
`;

class FooterMobile extends React.Component {
	
	
	componentDidMount () {
		const script = document.createElement("script");

		script.src = "https://www.trustmarkthai.com/callbackData/initialize.js?t=ac54f3-22-5-c2eebed8b4423b9f63231bdf5fe34ef38a451cc";
		script.id = 'dbd-init';
		script.async = true;
		document.body.appendChild(script);
	}

	render() {
		return (
			<div>
				<MediaQuery maxWidth={767}>
					<TopContainer onClick={() => window.scrollTo(0, 0)}>
						<i className="fal fa-chevron-up"></i>
						<span>กลับขึ้นด้านบน</span>
					</TopContainer>
				</MediaQuery>
				<FooterContainer>
					<div className="container">
						<MenuContainer>
							<Col xs={12}>
								<Title>ติดต่อฝ่ายลูกค้าสัมพันธ์</Title>
								<p>
									บริษัท จิซทิกซ์ จำกัด<br/>
									เลขที่ 101 , ตึก ทรู ดิจิทัล พาร์ค ชั้น 15 ห้องเลขที่ 1507-1509<br/>
									ถนน สุขุมวิท แขวงบางจาก เขตพระโขนง กรุงเทพ 10260
								</p>
								<p>
									อีเมล : <a href="mailto:service@giztix.com" style={{color: "#ccc"}}>service@giztix.com</a><br/>
									เบอร์โทรศัพท์ : 02-059-7149<br/>
									จันทร์ - ศุกร์ เวลา 09.00 - 18.00 น.
								</p>
							</Col>
						</MenuContainer>
						<DownloadContainer>
							<Col sm={12}>
								<Title>ติดตามข่าวสารและความเคลื่อนไหวได้ที่</Title>
								<SocailLinkContainer>
									<SocailLink href="https://www.facebook.com/giztix/" target="_blank" title="Facebook" aria-label="Facebook"><img width='28px' height='auto' src={facebookfooter} alt="line_icon" /></SocailLink>
									<SocailLink href="https://line.me/R/ti/p/@giztix" target="_blank" title="Line" aria-label="Line"><img width='29px' height='auto' style={{backgroundColor: '#868686',borderRadius: '4px'}} src={linefooter} alt="line_icon" /></SocailLink>
									<SocailLink href="https://www.instagram.com/giztix_official/" target="_blank" title="Instagram" aria-label="Instagram"><img width='30px' height='auto' src={instagram} alt="line_icon" /></SocailLink>
									<SocailLink href="https://www.youtube.com/channel/UCDoz39PN2yzoQB5d0-xcgJg" target="_blank" title="Youtube" aria-label="Youtube"><img width='35px' height='auto' src={youtube} alt="line_icon" /></SocailLink>
								</SocailLinkContainer>
							</Col>
						</DownloadContainer>
						<DBDContainer>
							<Col sm={12} ><div id="Certificate-banners"></div></Col>
						</DBDContainer>
						<CopyRightContainer>
							<Col xs={12}>
								<span>© 2019 GIZTIX - สงวนลิขสิทธิ์ (เวอร์ชั่น 1)</span>
							</Col>
						</CopyRightContainer>
					</div>
				</FooterContainer>
			</div>
		);
	}
}

export default FooterMobile;
